import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Error from "../../components/error";
import { FormLabel, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  formLabelRoot: {
    display: "flex",
    paddingLeft: "0px",
    alignItems: "center",
    color: "#000",
    fontSize: "14px",
  },
  formLabelInline: {
    paddingLeft: "0px",
    color: "#585555",
    fontSize: "30px",
    padding: "10px",
    display: "flex",
    fontWeight: "500",
    justifyContent: "center !important",
  },
}));

const validateDate = (date, mindate, maxdate) => {
  if (!date) return "Date is required.";

  const [month, day, year] = date.split("/").map(Number);

  if (!month || !day || !year) {
    return "Invalid date format or value.";
  }

  if (month < 1 || month > 12) return "Month must be between 1 and 12.";
  if (day < 1 || day > 31) return "Day must be between 1 and 31.";
  if (year < 1000 || year > 9999) return "Year must be a valid 4-digit number.";

  const parsedDate = new Date(year, month - 1, day);

  if (
    parsedDate.getFullYear() !== year ||
    parsedDate.getMonth() + 1 !== month ||
    parsedDate.getDate() !== day
  ) {
    return "Invalid date format or value.";
  }

  const minDate = mindate ? new Date(mindate) : null;
  const maxDate = maxdate ? new Date(maxdate) : null;

  if (minDate && parsedDate < minDate) {
    return "Maximum age limit exceeded.";
  }

  if (maxDate && parsedDate > maxDate) {
    return "You must be at least 18 years old.";
  }

  return "";
};

const MaskedDateField = ({
  name,
  label,
  onDateChange,
  theme_id,
  type,
  validateOptions,
  description,
  validate,
  validationCallback,
  maxdate,
  mindate,
}) => {
  const [date, setDate] = useState("");
  const [error, setError] = useState("");
  const classes = useStyles();

  const handleInputChange = (e) => {
    let value = e.target.value.replace(/[^0-9]/g, "");
    if (value.length > 8) value = value.slice(0, 8);

    const parts = [];
    if (value.length > 4) {
      parts.push(value.slice(0, 2), value.slice(2, 4), value.slice(4));
    } else if (value.length > 2) {
      parts.push(value.slice(0, 2), value.slice(2));
    } else {
      parts.push(value);
    }

    setDate(parts.join("/"));
    setError("");
  };

  const handleBlur = () => {
    const validationError = validateDate(date, mindate, maxdate);
    setError(validationError);
    validationCallback(!!validationError);

    if (!validationError) {
      const parsedDate = new Date(date);
      const localDate = new Date(
        parsedDate.getTime() - parsedDate.getTimezoneOffset() * 60000
      );
      const formattedDate = localDate.toISOString().split("T")[0];
      onDateChange(formattedDate);
    }
  };

  useEffect(() => {
    if (validate) {
      const validationError = validateDate(date, mindate, maxdate);
      setError(validationError);
      validationCallback(!!validationError);
    }
  }, [validate, date, mindate, maxdate, validationCallback]);

  return (
    <div className="date-scroll-picker">
      {!!label && theme_id !== 3 && theme_id !== 2 && (
        <FormLabel
          className={
            type === "survey" ? classes.formLabelInline : classes.formLabelRoot
          }
        >
          {label}&nbsp;
          {validateOptions?.required && type !== "survey" && (
            <span className="mandatory">*</span>
          )}
          {!!description && (
            <Tooltip placement="top-start" title={description} />
          )}
        </FormLabel>
      )}
      <div className="date-picker-div">
        <input
          type="text"
          value={date}
          placeholder="MM/DD/YYYY"
          onChange={handleInputChange}
          onBlur={handleBlur}
          maxLength="10"
          className="form-control form-control-lg date-picker-input-field"
        />
        {error && <Error errorMessage={error} />}
      </div>
    </div>
  );
};

MaskedDateField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onDateChange: PropTypes.func.isRequired,
  theme_id: PropTypes.number,
  type: PropTypes.string,
  validateOptions: PropTypes.object,
  description: PropTypes.string,
  validate: PropTypes.bool,
  validationCallback: PropTypes.func.isRequired,
  maxdate: PropTypes.string,
  mindate: PropTypes.string,
};

export default MaskedDateField;
