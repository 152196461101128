import React, { useEffect, useRef, useState } from "react";
import { Grid } from "@material-ui/core";
import { object, string, func } from "prop-types";
import "./date-field.scss";
import Error from "../../components/error";
import fieldValidation from "../../utils/validate";
import { checkValidationRule } from "../../utils/validate";
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import Tooltip from "../../views/tool-tip";
import { CDatePicker } from '@coreui/react-pro';

const useStyles = makeStyles((theme) => ({
  formLabelroot: {
    display: "flex",
    paddingLeft: "0px",
    alignItems: "center",
    color: "#000",
    fontSize: "14px",
  },
  formLabelrootInline: {
    paddingLeft: "0px",
    color: "#585555",
    fontSize: "30px",
    padding: "10px",
    display: "flex",
    fontWeight: "500",
    justifyContent: "center !important",
  },
  mainDivSurveyType: {
    float: "left",
    paddingLeft: "3px",
    backgroundColor: "white",
    width: "38px",
    height: "58px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  IconOther: {
    color: "#7c7d7d",
  },
}));

const DateFieldWithNextButton = ({
  name,
  fieldAttributes,
  label,
  validate,
  validateExecutive,
  description,
  validationRule,
  validateOptions,
  validationCallback,
  type,
  value,
  onDateChange,
  theme_id,
  maxdate,
  iconName,
  mindate,
  yearsCount,
  themecolors,
  activestep,
}) => {
  const [errors, setError] = useState({});
  const col = themecolors.onHoverInColor;
  const [colHovor, SetolHovor] = useState(themecolors.textColor);
  const [startDate, setStartDate] = useState(value || "");
  const dateInputRef = useRef(null);

  const handleDateChange = (e) => {
    const selectedDate = new Date(e);
    const localDate = new Date(selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60000);
    const formattedDate = localDate.toISOString().split("T")[0];
    setStartDate(formattedDate); 
    onDateChange(formattedDate);
  };

  const validateFields = (isVisible, e) => {
    const { required } = validateOptions;
    if (required) {
      const errorMsg = fieldValidation.required(startDate, name);
      if (errorMsg) {
        handleErrors(errorMsg, isVisible, e);
        return;
      }
    }
    handleErrors("", isVisible, e);
  };

  const handleErrors = (errorMsg, isVisible, e) => {
    const err = { [name]: errorMsg };
    if (checkValidationRule(isVisible, validationRule)) {
      setError({ ...err });
    } else if (isVisible === "VALIDATE_ON_TYPE") {
      setError({ err: "" });
    }
    validationCallback(!!errorMsg);
  };

  useEffect(() => {
    if (validate || validateExecutive) {
      validateFields("VALIDATE_ON_FORM_SUBMIT");
    }
  }, [validate, validateExecutive]);

  const changeColorHoverIn = () => {
    SetolHovor(col);
  };
  const changeColorHoverOut = () => {
    SetolHovor(themecolors.textColor);
  };

  const openDatePicker = () => {
    if (dateInputRef.current) {
      try {
        dateInputRef.current.showPicker();
      } catch (error) {
        dateInputRef.current.focus();
      }
    }
  };
  const classes = useStyles();

  return (
    <Grid>
      {!!label && theme_id !== 3 && theme_id !== 2 && (
        <FormLabel
          className={
            type === "survey"
              ? classes.formLabelrootInline
              : classes.formLabelroot
          }
        >
          {label}&nbsp;
          {validateOptions.required && type !== "survey" && (
            <span className="mandatory">*</span>
          )}
          {!!description && (
            <Tooltip placement="top-start" title={description} />
          )}
        </FormLabel>
      )}
      <div
        onMouseOver={changeColorHoverIn}
        onMouseLeave={changeColorHoverOut}
        onClick={openDatePicker}
      >
        <div
          style={{
            position: "relative",
            display: "inline-block",
            width: "100%",
          }}
          className="hover-div-calender"
        >
           <CDatePicker
            placeholder="Select Date of Birth" 
            locale="en-ca" 
            minDate={mindate}
            maxDate={maxdate}
            value={startDate}
            onDateChange={handleDateChange}
            className='coreui-calender '
            confirmButton={true}
            />
        </div>
      </div>
      {!!errors[name] && theme_id !== 3 && (
        <Error
          type={`${type === "survey" ? "dateError" : ""}`}
          errorMessage={errors[name]}
        />
      )}
    </Grid>
  );
};

DateFieldWithNextButton.propTypes = {
  fieldAttributes: object,
  classname: string,
  label: string.isRequired,
  name: string.isRequired,
  validationCallback: func.isRequired,
};

DateFieldWithNextButton.defaultProps = {
  fieldAttributes: {},
  classname: "",
  validateOptions: {
    check: false,
  },
  theme_id: 0,
};

export default DateFieldWithNextButton;
