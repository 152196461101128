import React from "react";
import InputField from "../../views/input-field/input-field";
import constants from "../../constants";
import { button_appearances } from "../../views/button";
import CustomSelectBox from "../../views/dropdown/custom-dropdown";
import ComboBox from "../../views/combo-box";
import RadioBox from "../../views/radio-box/radio-box";
import DropDownAutocomplete from "../../views/drop-down-auto-complete/DropdownAndAutoComplete";
import CheckBox from "../../views/check-box/checkBox";
import DateField from "../../views/date-field";
import FileField from "../../views/file-field/file-field";
import TextArea from "../../views/text-area/index";
import Button from "../../views/button";
import SurveyFormSliderNew from "../../views/survey-form-slider/survey-form-slider-new";
import NumberField from "../../views/number-field";
import { leadData } from "../../actions/manage-site-action-type";
import { connect } from "react-redux";
import axios from "axios";
import RangeSlider from "../../views/range-slider";
import AutoCompletePostSuburb from "../../views/drop-down-auto-complete/autocomplete-post-suburb";
import FifthLayoutDynamicForm from "../dynamic-form/fifthlayout-dynamic-form";
import Utils from "../../utils";
import themecolors from "../../utils/themecolors";
import Api from "../../api";
import DateFieldWithNextButton from "../../views/date-field/date-field-next";
import CustomDatePicker from "../../views/date-field/custom-date-picker";
import DateScrollPicker from "../../views/date-field/react-mobile-datepicker";
import MaskedDateField from "../../views/date-field/masked-date-input-field";

class FifthLayoutSurvey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      fieldsData: {},
      error: "",
      isValidate: false,
      activeStep: 0,
      isPhoneValid: true,
      backSteps: [],
      isDependantQuestion: false,
      defaultval: "(Keeling) Islands, WA, 6799",
    };
  }

  componentDidMount = () => {
    const queryString = window.location.search;
    sessionStorage.setItem('queryString', queryString);
    
    const { data } = this.props;
    data &&
      data.map((item) => {
        if (item.type !== "button" && item.name !== undefined) {
          let returnVal = this.returnValue(item);

          this.setState((prevState, props) => ({
            fields: {
              ...prevState.fields,
              [item.name]: returnVal,
            },
            [`has${item.name}Error`]: returnVal !== "" ? false : true,
          }));
        }
      });
  };

  returnValue = (item) => {
    var returnValue = "";

    if (item.value) {
      returnValue = item.value;
    } else if (item.values) {
      var valuesArray = [];
      // var temparr = [];
      if (item.type === "select") {
        valuesArray = item.values.filter((item) => {
          return item.selected === true;
        });
        valuesArray =
          valuesArray.length === 0
            ? item.values.filter((item, index) => {
              return index === 0;
            })
            : valuesArray;
      } else if (
        item.type === "autocomplete" &&
        item.className.includes("postcode_suburb")
      ) {
        return returnValue;
      } else {
        valuesArray = item.values.filter((item) => {
          return item.selected === true;
        });
      }

      if (valuesArray.length > 0) {
        valuesArray.map((item) => {
          returnValue = returnValue + "," + item.value;
        });
      } else {
        returnValue = "";
      }
    } else {
      returnValue = "";
    }

    returnValue = returnValue.replace(/^,|,$/g, "");
    return returnValue;
  };

  handleFileZoneChange = (field, data, e) => {
    const { target } = e;
    const { fields } = this.state;
    fields[field] = target;
    this.setState({ fields });
  };

  dataSetfield = () => {
    const { fields, backSteps, activeStep } = this.state;
    const { data } = this.props;
    // let siteFormData = siteData.dynamic_forms.filter((item) => {
    //   return item.page_id === 6;
    // })[0];

    let formData = [];

    this.setState({ isValidate: true });
    // var cont = 0;

    Object.keys(fields).map((key, index) => {
      var fielddata = data.filter((inner_item) => {
        return inner_item.name === key;
      })[0];

      var formValue = fields[key];
      if (
        fielddata &&
        fielddata.type === "checkbox-group" &&
        fielddata.isOtherSelected &&
        fielddata.isOtherText
      ) {
        formValue = formValue + "," + fielddata.isOtherText;
        formValue = formValue.replace(/^,|,$/g, "");
      }
      if (
        fielddata &&
        fielddata.type === "radio-group" &&
        fielddata.isOtherSelected &&
        fielddata.isOtherText
      ) {
        formValue = fielddata.isOtherText;
      }

      // cont++;

      var ifExist =
        backSteps &&
        backSteps.length > 0 &&
        backSteps.filter((item) => {
          return item === index;
        });

      //Only track form fields which are being filled by dependancy questions, other will skip
      if ((ifExist && ifExist.length > 0) || index === activeStep) {
        formData.push({
          label: fielddata.label,
          value: formValue,
          name: fielddata.name,
          subtype:
            fielddata.subtype && fielddata.subtype !== undefined
              ? fielddata.subtype
              : fielddata.type,
        });
      }
    });
  };

  isIncludeProfanityWord = (str, word) => {
    return str === word;
  };

  headerTag = (item, index) => {
    return (
      <div
        key={index}
        id={item.name}
        className={
          this.state.activeStep >= 0 ? `step_${this.state.activeStep}` : ""
        }
        style={{ display: "flex" }}
      >
        <item.subtype>
          {item.label}
          {window.location.host === "www.ds.supportwithdebt.com.au" ||
            (window.location.host === "ds.supportwithdebt.com.au" && (
              <>
                <a
                  href="http://tracking.popsplot.com.au/aff_c?offer_id=6514&aff_id=8181"
                  target="blank"
                >
                  {" Click here"}
                </a>
                {" to find out more"}
              </>
            ))}
        </item.subtype>
      </div>
    );
  };

  buttonTag = (item, index) => {
    return (
      <div
        className="space-between"
        key={index}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div style={{ display: "flex", width: "200px" }}>
          <Button
            type={item.subtype}
            name={item.name}
            appearance={
              item.style
                ? button_appearances[item.style]
                : button_appearances.primary_large
            }
            //   clickHandler={this.handleSubmit}
            text={item.label}
          />
        </div>
      </div>
    );
  };

  handleChange = (field, data, e) => {
    const { target } = e;
    const { fields } = this.state;
    fields[field] = target.value;
    this.setState({
      field,
      [`has${[e.target.name]}Error`]: false,
      isPhoneValid: true,
    });
  };

  comboChange = (value, data, field) => {
    let { fields, selectedArr } = this.state;
    fields[field] = value.value;
    selectedArr = value;
    this.setState({ fields, [`customddl-${field}`]: selectedArr });
  };

  comboClick = (value, field, displayName) => {
    const { fields } = this.state;
    fields[field] = value !== null ? value[displayName] : "";
    this.setState({ fields });
  };

  handleCustomDropDownClick = (options, value, name) => {
    const { fields } = this.state;
    fields[name] = value;
    this.setState(
      {
        fields,
        [`has${[name]}Error`]: false,
        isPhoneValid: true,
      },
      () => {
        this.setTimeOutForNextClick();
      }
    );
  };

  postCodeAutoComplteOnChange = (value, data, field) => {
    //debugger;
    let { fields, selectedArr } = this.state;
    fields[field] = value;
    selectedArr = value;
    this.setState(
      {
        fields,
        [`customddl-${field}`]: selectedArr,
        [`has${[field]}Error`]: false,
      },
      () => {
        this.setTimeOutForNextClick();
      }
    );
  };

  PostZipAutoCompleteTag = (item, index) => {
    const { isValidate, activeStep } = this.state;
    const { siteData } = this.props;
    const selectedArr = this.state[`customddl-${item.name}`];
    var theme = siteData.primary_color;
    var selectedArry = selectedArr === undefined ? "" : selectedArr;
    let siteFormData = siteData.content_manage_sites.filter((item) => {
      return item.page_id === 6;
    })[0];
    return (
      <>
        <div
          className="space-between"
          key={index}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <AutoCompletePostSuburb
            type={"survey"}
            options={item.values}
            name1={item.name}
            label={item.label}
            handleChange={(value) =>
              this.postCodeAutoComplteOnChange(value, item, item.name)
            }
            description={item.description}
            validateOptions={{
              check: true,
              required: item.required,
            }}
            allowMultiple={item.multiple}
            validate={isValidate}
            validationCallback={(res) =>
              this.setState({
                [`has${item.name}Error`]: res,
                isValidate: false,
              })
            }
            value={selectedArry}
            requireValidOption={item.requireValidOption}
            theme_id={theme}
            iconName={item.icon}
            placeholder={item.placeholder}
            country={siteData.country}
            themecolors={themecolors.colors(
              theme,
              siteData.theme,
              siteFormData.page_title
            )}
            activestep={activeStep}
          />
        </div>
      </>
    );
  };

  DropDownAutocompleteTag = (item, index) => {
    const { isValidate, activeStep } = this.state;
    const { siteData } = this.props;

    const selectedArr = this.state[`customddl-${item.name}`];
    var theme = siteData.primary_color;
    let siteFormData = siteData.content_manage_sites.filter((item) => {
      return item.page_id === 6;
    })[0];
    var selectedArry =
      selectedArr === undefined
        ? item.values &&
        item.values.filter((inneritem) => {
          return inneritem.selected === true;
        })[0]
        : selectedArr;

    return (
      <div
        className="space-between"
        key={index}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <DropDownAutocomplete
          type={"survey"}
          options={item.values}
          name={item.name}
          label={item.label}
          handleChange={(value) => this.comboChange(value, item, item.name)}
          description={item.description}
          validateOptions={{
            check: true,
            required: true,
          }}
          allowMultiple={item.multiple}
          validate={isValidate}
          validationCallback={(res) =>
            this.setState({ [`has${item.name}Error`]: res, isValidate: false })
          }
          value={selectedArry}
          requireValidOption={item.requireValidOption}
          theme_id={theme}
          iconName={item.icon}
          // theme_id={siteData.theme}
          themecolors={themecolors.colors(
            theme,
            siteData.theme,
            siteFormData.page_title
          )}
          activestep={activeStep}
        />
      </div>
    );
  };

  selectTag = (item, index) => {
    const { isValidate, fields, activeStep } = this.state;
    const { siteData } = this.props;
    const dataIcon = siteData.content_manage_sites.filter(
      (item) => item.page_id === 1
    )[0].col_three;

    var theme = siteData.primary_color;
    let siteFormData = siteData.content_manage_sites.filter((item) => {
      return item.page_id === 6;
    })[0];
    return (
      <div
        className="space-between"
        key={index}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CustomSelectBox
          type={"survey"}
          options={item.values}
          name={item.name}
          label={item.label}
          fieldAttributes={{
            onChange: this.handleChange.bind(this, item.name, item),
          }}
          description={item.description}
          validateOptions={{
            check: true,
            required: true,
          }}
          allowMultiple={item.multiple}
          validate={isValidate}
          validationCallback={(res) =>
            this.setState({ [`has${item.name}Error`]: res, isValidate: false })
          }
          value={fields[item.name] || ""}
          onCustomDropDownClick={(value) =>
            this.handleCustomDropDownClick(item.values, value, item.name)
          }
          theme={theme}
          iconName={"icon-money5"}
          layoutTheme={siteData.theme}
          displayicon={dataIcon}
          data={this.state.fields}
          themecolors={themecolors.colors(
            theme,
            siteData.theme,
            siteFormData.page_title
          )}
          activestep={activeStep}
        />
      </div>
    );
  };

  handleChangeRadio = (e, data) => {
    const { fields } = this.state;
    fields[e.target.name] = e.target.value;
    data.isOtherSelected = false;
    data.isOtherText = "";
    var status = e.target.value === "other" ? true : false;
    this.setState({ fields, [`has${[e.target.name]}Error`]: status }, () => {
      this.setTimeOutForNextClick();
    });
  };

  handleChangeRadioOther = (otherTxt, isSelected, data) => {
    data.isOtherSelected = isSelected;
    data.isOtherText = otherTxt;

    const { fields } = this.state;
    fields[data.name] = "other";

    var status = otherTxt === "" ? true : false;
    this.setState({ fields, [`has${[data.name]}Error`]: status });
  };

  radioboxTag = (item, index) => {
    const { isValidate, fields, activeStep } = this.state;
    const { siteData } = this.props;

    var theme = siteData.primary_color;
    const dataIcon = siteData.content_manage_sites.filter(
      (item) => item.page_id === 1
    )[0].col_three;

    const surveyData = siteData.content_manage_sites.filter(
      (item) => item.page_id === 6
    )[0];

    return (
      <div
        className="space-between radioBtnsGroup"
        key={index}
        style={{ display: "flex" }}
      >
        <RadioBox
          type={"survey"}
          data={item}
          key={index}
          value={fields[item.name] || ""}
          name={item.name}
          onChangeHandle={(res) => this.handleChangeRadio(res, item)}
          validateOptions={{
            required: item.required,
          }}
          validate={isValidate}
          validationCallback={(res) =>
            this.setState({ [`has${item.name}Error`]: res, isValidate: false })
          }
          onChangeOther={(res, res1) =>
            this.handleChangeRadioOther(res, res1, item)
          }
          iconName={"icon-date"}
          theme={theme}
          templatetheme={siteData.theme}
          displayicon={dataIcon}
          surveyLayout={surveyData.page_title}
          themecolors={themecolors.colors(
            theme,
            siteData.theme,
            surveyData.page_title
          )}
          activestep={activeStep}
        />
      </div>
    );
  };

  AutocompleteTag = (item, index) => {
    const { fields, isValidate } = this.state;

    return (
      <div className="space-between" key={index}>
        <ComboBox
          type={"survey"}
          clearOnEscape={true}
          classname={item.className}
          name={item.name}
          arrList={item.values}
          label={item.label}
          handleChange={(value) => this.comboChange(value, item.name, item)}
          handelClickEvent={(value) =>
            this.comboClick(value, item.name, "value")
          }
          validateOptions={{
            check: true,
            required: item.required,
          }}
          validate={isValidate}
          validationCallback={(res) =>
            this.setState({ [`has${item.name}Error`]: res, isValidate: false })
          }
          value={fields[item.name] || ""}
          placeholder={item.placeholder}
          requireValidOption={item.requireValidOption}
        />
      </div>
    );
  };

  paragraphTag = (item, index) => (
    <div key={index} className="space-between">
      <item.subtype
        id={`step_${this.state.activeStep}_${item.name}`}
        key={index}
      >
        {item.label}
      </item.subtype>
    </div>
  );

  getCommaSepratedValuesCheckbox = (data) => {
    var selectedValues = "";
    data &&
      data.values &&
      data.values.map((item) => {
        if (item.selected === true) {
          selectedValues += "," + item.label;
        }
      });
    return (selectedValues = selectedValues.replace(/^,|,$/g, ""));
  };

  checkboxErrorStatus = (data, selectedValues) => {
    return data.isOtherSelected && data.isOtherText === ""
      ? true
      : data.isOtherSelected && data.isOtherText !== ""
        ? false
        : selectedValues === ""
          ? true
          : false;
  };

  handleChangeCheckbox = (e, data) => {
    const { fields } = this.state;
    var selectedValues = this.getCommaSepratedValuesCheckbox(data);

    fields[data.name] = selectedValues;
    var status = this.checkboxErrorStatus(data, selectedValues);
    this.setState({ fields, [`has${[data.name]}Error`]: status });
  };

  handleChangeCheckboxOther = (otherTxt, isSelected, data) => {
    data.isOtherSelected = isSelected;
    data.isOtherText = otherTxt;
    var selectedValues = this.getCommaSepratedValuesCheckbox(data);
    var status = this.checkboxErrorStatus(data, selectedValues);
    this.setState({ [`has${[data.name]}Error`]: status });
  };

  checkboxTag = (item, index) => {
    const { fields, isValidate, activeStep } = this.state;
    const { siteData } = this.props;
    const dataIcon = siteData.content_manage_sites.filter(
      (item) => item.page_id === 1
    )[0].col_three;
    var theme = siteData.primary_color;
    const surveyData = siteData.content_manage_sites.filter(
      (item) => item.page_id === 6
    )[0];

    return (
      <div key={index} className="space-between chkbox_survey">
        <CheckBox
          type={"survey"}
          label={item.label}
          className={item.className}
          options={item.values || []}
          name={item.name}
          validateOptions={{
            required: item.required,
          }}
          validate={isValidate}
          toggle={item.toggle}
          inline={item.inline}
          other={item.other}
          validationCallback={(res) =>
            this.setState({ [`has${item.name}Error`]: res, isValidate: false })
          }
          id={"isHelpdesk_id"}
          value={fields[item.name] || ""}
          optionText={""}
          isDisabled={false}
          onChange={(res) => this.handleChangeCheckbox(res, item)}
          onChangeOther={(res, res1) =>
            this.handleChangeCheckboxOther(res, res1, item)
          }
          displayName={"title"}
          description={item.description}
          data={item}
          theme={theme}
          templatetheme={siteData.theme}
          iconName={"icon-money4"}
          displayicon={dataIcon}
          surveyLayout={surveyData.page_title}
          themecolors={themecolors.colors(
            theme,
            siteData.theme,
            surveyData.page_title
          )}
          activestep={activeStep}
        />
      </div>
    );
  };

  handleDateChange = (value, field) => {
    const { fields } = this.state;
    fields[field] = value;
    this.setState({ fields, [`has${[field]}Error`]: false }, () => {
      this.setTimeOutForNextClick();
    });
  };

  handleDateWithNextButtonChange = (value, field) => {
    const { fields } = this.state;
    fields[field] = value;
    this.setState({ fields, [`has${[field]}Error`]: false });
  };


  setTimeOutForNextClick = () => {
    setTimeout(() => {
      this.handleNextStep();
    }, 200);
  };

  dateTag = (data, index) => {
    var maxdate = new Date(
      new Date().getFullYear() + 100,
      new Date().getMonth(),
      new Date().getDate()
    );
    var mindate = new Date(
      new Date().getFullYear() - 100,
      new Date().getMonth(),
      new Date().getDate()
    );
    const { siteData } = this.props;
    var theme = siteData.primary_color;
    let siteFormData = siteData.content_manage_sites.filter((item) => {
      return item.page_id === 6;
    })[0];
    let isdob =
      data.className.includes("dob") ||
      data.className.includes("DOB") ||
      data.className.includes("Dob");

    if (isdob) {
      maxdate = new Date(
        new Date().getFullYear() - 18,
        new Date().getMonth(),
        new Date().getDate()
      );
      mindate = new Date(
        new Date().getFullYear() - 120,
        new Date().getMonth(),
        new Date().getDate()
      );
    }

    const { fields, isValidate, activeStep } = this.state;
    return (
      <div
        key={index}
        className="space-between input_date"
        style={{ padding: "0px" }}
      >
        <DateField
          type={"survey"}
          key={index}
          label={data.label}
          description={data.description}
          name={data.name}
          validateOptions={{
            required: data.required,
          }}
          validate={isValidate}
          validationCallback={(res) =>
            this.setState({ [`has${data.name}Error`]: res, isValidate: false })
          }
          fieldAttributes={{
            placeholderText: data.placeholder,
            // onChange: (date) => this.handleDateChange(date, data.name),
            // value: fields[data.name] || "",
          }}
          onDateChange={(date) => this.handleDateChange(date, data.name)}
          value={fields[data.name] || ""}
          maxdate={maxdate}
          mindate={mindate}
          theme={theme}
          iconName={`${data.icon}`}
          themecolors={themecolors.colors(
            theme,
            siteData.theme,
            siteFormData.page_title
          )}
          activestep={activeStep}
        />
      </div>
    );
  };

  dateTagWithNextButton = (data, index) => {
    var maxdate = new Date(
      new Date().getFullYear() + 100,
      new Date().getMonth(),
      new Date().getDate()
    );
    var mindate = new Date(
      new Date().getFullYear() - 100,
      new Date().getMonth(),
      new Date().getDate()
    );
    const { siteData } = this.props;
    var theme = siteData.primary_color;
    let siteFormData = siteData.content_manage_sites.filter((item) => {
      return item.page_id === 6;
    })[0];
    let isdob =
      data.className.includes("dob") ||
      data.className.includes("DOB") ||
      data.className.includes("Dob");

    if (isdob) {
      maxdate = new Date(
        new Date().getFullYear() - 18,
        new Date().getMonth(),
        new Date().getDate()
      );
      mindate = new Date(
        new Date().getFullYear() - 120,
        new Date().getMonth(),
        new Date().getDate()
      );
    }

    const { fields, isValidate, activeStep } = this.state;
    return (
      <div
        key={index}
        className="space-between input_date"
        style={{ padding: "0px" }}
      >
        <MaskedDateField    
          type={"survey"}
          key={index}
          label={data.label}
          description={data.description}
          name={data.name}
          validateOptions={{
            required: data.required,
          }}
          validate={isValidate}
          validationCallback={(res) =>
            this.setState({ [`has${data.name}Error`]: res, isValidate: false })
          }
          fieldAttributes={{
            placeholderText: data.placeholder,
            // onChange: (date) => this.handleDateChange(date, data.name),
            // value: fields[data.name] || "",
          }}
          onDateChange={(date) => this.handleDateWithNextButtonChange(date, data.name)}
          value={fields[data.name] || ""}
          maxdate={maxdate}
          mindate={mindate}
          theme={theme}
          iconName={`${data.icon}`}
          themecolors={themecolors.colors(
            theme,
            siteData.theme,
            siteFormData.page_title
          )}
          activestep={activeStep}
           />
      </div>
    );
  };

  fileTag = (data, index) => {
    const { fields, isValidate, activeStep } = this.state;
    switch (data.subtype) {
      case "file":
        return (
          <div key={index} className="space-between">
            <FileField
              type={"survey"}
              key={index}
              label={data.label}
              placeHolder={data.placeholder}
              name={data.name}
              description={data.description}
              validateOptions={{
                required: data.required,
              }}
              multiple={data.multiple}
              validate={isValidate}
              validationCallback={(res) =>
                this.setState({
                  [`has${data.name}Error`]: res,
                  isValidate: false,
                })
              }
              fieldAttributes={{
                autoComplete: "off",
                placeholder: data.placeholder,
                onChange: this.handleChange.bind(this, data.name, data),
                value: fields[data.name] || "",
              }}
              activestep={activeStep}
            />
          </div>
        );

      default:
        return <></>;
    }
  };

  handleRangeSliderChange = (name, value) => {
    const { fields } = this.state;
    fields[name] = value;
    this.setState({
      name,
      [`has${[name]}Error`]: false,
      isPhoneValid: true,
    });
  };

  numberFieldTag = (data, index) => {
    const { fields, isValidate, activeStep } = this.state;
    const { siteData } = this.props;
    var theme = siteData.primary_color;
    let siteFormData = siteData.content_manage_sites.filter((item) => {
      return item.page_id === 6;
    })[0];

    let maxValue = data.max;

    if (["ca.financesolver.co", "www.ca.financesolver.co"].includes(Utils.getHost().websiteUrl)) {
      const dob = fields["dob"];
      if (dob) {
        const dobDate = new Date(dob);
        const today = new Date();

        let age = today.getFullYear() - dobDate.getFullYear();
        if (
            today.getMonth() < dobDate.getMonth() || 
            (today.getMonth() === dobDate.getMonth() && today.getDate() < dobDate.getDate())
        ) {
            age -= 1;
        }
    
        if (age >= 18 && age <= 44) {
          maxValue = 1500000;
        } else if (age >= 45 && age <= 54) {
          maxValue = 1000000;
        } else if (age >= 55 && age <= 59) {
          maxValue = 750000;
        } else if (age >= 60 && age <= 70) {
          maxValue = 500000;
        } else if (age >= 71) {
          maxValue = 25000;
        }
      }
    }

    let isRangeSlider =
      data.className.toLowerCase().includes("rangeslider") ||
      data.className.toLowerCase().includes("range-slider");
    var selectedValue = isNaN(fields[data.name])
      ? 0
      : parseInt(fields[data.name]);

    return (
      <div key={index} className="space-between">
        {isRangeSlider ? (
          <>
            <RangeSlider
              type={"survey"}
              key={index}
              index={index}
              label={data.label}
              placeHolder={data.placeholder}
              name={data.name}
              description={data.description}
              className={data.className}
              min={data.min}
              max={["ca.financesolver.co", "www.ca.financesolver.co"].includes(Utils.getHost().websiteUrl) ? maxValue:data.max}
              step={data.step}
              theme={theme}
              iconName={data.icon}
              onRangeSliderChange={this.handleRangeSliderChange.bind()}
              value={selectedValue}
              themecolors={themecolors.colors(
                theme,
                siteData.theme,
                siteFormData.page_title
              )}
              activestep={activeStep}
            />
          </>
        ) : (
          <NumberField
            type={"survey"}
            key={index}
            label={data.label}
            placeHolder={data.placeholder}
            name={data.name}
            description={data.description}
            // className={data.className}
            min={data.min}
            max={data.max}
            step={data.step}
            theme={theme}
            iconName={data.icon}
            validateOptions={{
              check: true,
              required: data.required,
            }}
            validate={isValidate}
            validationCallback={(res) =>
              this.setState({
                [`has${data.name}Error`]: res,
                isValidate: false,
              })
            }
            fieldAttributes={{
              autoComplete: "off",
              type: "number",
              placeholder: data.placeholder,
              onChange: this.handleChange.bind(this, data.name, data),
              value: fields[data.name] || "",
            }}
            themecolors={themecolors.colors(
              theme,
              siteData.theme,
              siteFormData.page_title
            )}
            activestep={activeStep}
          />
        )}
      </div>
    );
  };

  textTag = (data, index) => {
    const {
      EMAIL_REGEX,
      MOBILE_NUMBER,
      PASSWORD_REGEX,
      MOBILE_NUMBER_GENERIC,
    } = constants;
    const { fields, isValidate, isPhoneValid, activeStep } = this.state;
    const { siteData } = this.props;
    var theme = siteData.primary_color;
    let siteFormData = siteData.content_manage_sites.filter((item) => {
      return item.page_id === 6;
    })[0];
    switch (data.subtype) {
      case "text":
      case "email":
      case "tel":
      case "password":
        return (
          <div key={index} className="space-between" style={{ padding: "0px" }}>
            <InputField
              type={"survey"}
              key={index}
              label={data.label}
              placeHolder={data.placeholder}
              name={data.name}
              description={data.description}
              isPhoneValid={isPhoneValid}
              subtype={data.subtype}
              theme={theme}
              FirstName={this.state.fields.firstName}
              validateOptions={{
                check: true,
                reg:
                  data.subtype === "email"
                    ? EMAIL_REGEX
                    : data.subtype === "tel"
                      ? ["ca.financesolver.co", "www.ca.financesolver.co"].includes(Utils.getHost().websiteUrl) ? '' : siteData.country !== "AU" 
                        ? MOBILE_NUMBER_GENERIC 
                        : MOBILE_NUMBER
                      : data.subtype === "password"
                        ? PASSWORD_REGEX
                        : "",
                regMsg:
                  data.subtype === "email"
                    ? `Please enter a valid email address`
                    : data.subtype === "tel"
                      ? `Please enter a valid ${siteData.country === "NZ"
                        ? "New Zealand"
                        : siteData.country === "CA"
                          ? "Canadian"
                          : "Australian"
                      } phone number`
                      : data.subtype === "password"
                        ? "Password should have minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"
                        : "",
                required: data.required,
              }}
              validate={isValidate}
              validationCallback={(res) =>
                this.setState({
                  [`has${data.name}Error`]: res,
                  isValidate: false,
                })
              }
              fieldAttributes={{
                autoComplete: "off",
                type: data.subtype === "tel" ? "phone" : data.subtype,
                placeholder: data.placeholder,
                onChange: this.handleChange.bind(this, data.name, data),
                value: fields[data.name] || "",
              }}
              iconName={data.icon}
              themecolors={themecolors.colors(
                theme,
                siteData.theme,
                siteFormData.page_title
              )}
              activestep={activeStep}
            />
          </div>
        );

      default:
        return <></>;
    }
  };

  textAreaTag = (data, index) => {
    const { fields, isValidate, activeStep } = this.state;
    const { siteData } = this.props;
    var theme = siteData.primary_color;
    let siteFormData = siteData.content_manage_sites.filter((item) => {
      return item.page_id === 6;
    })[0];
    switch (data.subtype) {
      case "textarea":
        return (
          <div
            key={index}
            className="space-between text_areainput"
            style={{ padding: "0px" }}
          >
            <TextArea
              type={"survey"}
              key={index}
              label={data.label}
              placeHolder={data.placeholder}
              name={data.name}
              validateOptions={{
                required: data.required,
              }}
              className={data.className}
              description={data.description}
              validate={isValidate}
              theme={theme}
              iconName={`${data.icon}`}
              validationCallback={(res) =>
                this.setState({
                  [`has${data.name}Error`]: res,
                  isValidate: false,
                })
              }
              fieldAttributes={{
                autoComplete: "off",
                maxLength: data.maxlength,
                rows: data.rows,
                onChange: this.handleChange.bind(this, data.name, data),
                value: fields[data.name] || "",
              }}
              themecolors={themecolors.colors(
                theme,
                siteData.theme,
                siteFormData.page_title
              )}
              activestep={activeStep}
            />
          </div>
        );

      default:
        return <></>;
    }
  };

  callSwitch = (item) => {

    const { heading, siteData, onClick, hideReview, data, getSiteDataObject } = this.props;
    if (
      item.type === "autocomplete" ||
      item.type === "select" ||
      item.type === "radio-group" ||
      item.type === "date" ||
      item.type === "checkbox-group" ||
      item.type === "date-with-next-button" ||
      (item.type === "number" && item.className && (item.className.toLowerCase().includes("rangeslider") ||
        item.className.toLowerCase().includes("range-slider"))) ||
      (item.className && item.className.toLowerCase().includes("nm-view"))
    ) {
      if (this.state.activeStep === 1 && siteData.theme !== "5") {
        onClick();
      }
      switch (item.type) {
        case "header":
          return this.headerTag(item, 1);
        case "text":
          return this.textTag(item, 2);
        case "button":
          return this.buttonTag(item, 3);
        case "select":
          return this.selectTag(item, 4);
        case "autocomplete":
          //  return this.AutocompleteTag(item, 5);
          if (item.className.includes("postcode_suburb")) {
            return this.PostZipAutoCompleteTag(item, 5);
          } else {
            return this.DropDownAutocompleteTag(item, 5);
          }
        case "radio-group":
          return this.radioboxTag(item, 6);
        case "paragraph":
          return this.paragraphTag(item, 7);
        case "checkbox-group":
          return this.checkboxTag(item, 8);
        case "date":
          return this.dateTag(item, 9);
        case "date-with-next-button":
          return this.dateTagWithNextButton(item, 9);
        case "file":
          return this.fileTag(item, 10);
        case "textarea":
          return this.textAreaTag(item, 11);
        case "number":
          return this.numberFieldTag(item, 12);
        default:
          return <></>;
      }
    } else {
      var dat = Utils.getPageContent(getSiteDataObject, 6);
      if (dat.second_survey_content) {
        hideReview();
      }
      var dataset = item.filter((item1) => {
        return (
          (item1.type === "text" && (item1.className && !(item1.className.toLowerCase().includes("nm-view")))) ||
          item1.type === "button" ||
          (item1.type === "number" && item.className && !(item1.className.toLowerCase().includes("rangeslider") ||
            item1.className.toLowerCase().includes("range-slider") || item1.className.toLowerCase().includes("nm-view"))) ||
          (item1.type === "textarea" && (item1.className && !(item1.className.toLowerCase().includes("nm-view")))) ||
          (item1.type === "checkbox-group" && (item1.className && !(item1.className.toLowerCase().includes("nm-view"))))
        );
      });
      var text = Utils.TextOnSubmit();
      var dataset2 = item.filter((item1) => {
        if (
          (item1.type !== "text" || item.type === "text" && (item1.className && (item1.className.toLowerCase().includes("nm-view")))) &&
          item1.type !== "button" &&
          (item.type !== "number" || (item1.type === "number" && item.className && (item1.className.toLowerCase().includes("rangeslider") ||
            item1.className.toLowerCase().includes("range-slider") || item1.className.toLowerCase().includes("range-slider")))) &&
          (item1.type !== "textarea" || (item.type === "textarea" && item1.className && item1.className.toLowerCase().includes("nm-view"))) &&
          (item1.type !== "checkbox-group" || (item.type === "checkbox-group" && item1.className && item1.className.toLowerCase().includes("nm-view")))
        ) {
          return item1;
        }
      });

      var dupitem = {
        className: "btn-info btn",
        icon: "",
        label: `${text}`,
        name: "button-1620285735688",
        style: "info",
        subtype: "button",
        type: "button",
      };
      dataset.push(dupitem);

      return (
        <div className="controls">
          <div className="col-sm-12">
            <div className="descrioption-news">
              {dat.page_title === "azx5layout" ? (
                <h2 style={{ textAlign: "center" }} className="lastheading">
                  {heading}
                </h2>
              ) : (
                <></>
              )}
             {(Utils.getHost().websiteUrl !== 'mylifeinsurancecompare.financesolver.co' &&
                Utils.getHost().websiteUrl !== 'life.financesolver.co' &&
                dat.page_title !== "azx5layout") && (
                <p
                  id="right-side-description"
                  dangerouslySetInnerHTML={{
                    __html: dat.second_survey_content,
                  }}
                ></p>
              )}

            </div>
          </div>

          <FifthLayoutDynamicForm
            data={dataset}
            siteData={siteData}
            dataset={data}
            page_id={6}
            theme_id={siteData.theme}
            prevData={this.state.fields}
            formData={dataset2}
            activestep={this.state.activeStep}
          />
        </div>
      );
    }
  };

  redirectToDoNotDisturb = (fieldName, value) => {
    // console.log("fieldName", fieldName);
    // console.log("value", value);
    var host = Utils.getHost().websiteUrl;//window.location.host;
    if (
      (window.location.host === "www.nz2.quickandeasyhearing.com" ||
        window.location.host === "nz2.quickandeasyhearing.com"
      ) &&
      fieldName.toLowerCase() === "postcode"
    ) {
      var postcodeArray = value.split(",");
      var postcode =
        postcodeArray && postcodeArray.length > 1 && postcodeArray[2].trim();
      if (
        (parseInt(postcode) >= 4000 && parseInt(postcode) <= 4999) ||
        (parseInt(postcode) >= 9000 && parseInt(postcode) <= 9999)
      ) {
        Utils.historyPush("/do-not-qualify");
      }
    }
    if (
      (window.location.host === "www.2022.myenergycompare.com.au" ||
        window.location.host === "2022.myenergycompare.com.au" ||
        window.location.host === "www.gs.myenergycompare.com.au" ||
        window.location.host === "gs.myenergycompare.com.au" ||
        window.location.host === "www.myenergycompare.com.au" ||
        window.location.host === "myenergycompare.com.au") &&
      (fieldName.toLowerCase() === "postcode" ||
        fieldName.toLowerCase() === "zip")
    ) {
      var postcodeArray = value.split(",");
      var postcode =
        postcodeArray && postcodeArray.length > 1 && postcodeArray[2].trim();
      var state =
        postcodeArray && postcodeArray.length > 1 && postcodeArray[1].trim();

      if (
        parseInt(postcode) < 2000 ||
        parseInt(postcode) > 4581 ||
        (state.toLowerCase() !== "nsw" && state.toLowerCase() !== "vic" && state.toLowerCase() !== "qld")
      ) {
        Utils.historyPush("/do-not-qualify");
      }
    }
    if (
      (window.location.host === "www.businessfundstoday.net" ||
        window.location.host === "businessfundstoday.net" ||
        window.location.host === "biz.businessfundstoday.net" ||
        window.location.host === "www.biz.businessfundstoday.net" ||
        window.location.host === "v2.businessfundstoday.net" ||
        window.location.host === "www.v2.businessfundstoday.net" ||
        window.location.host === "businessloan.financesolver.co" ||
        window.location.host === "www.businessloan.financesolver.co" ||
        window.location.host === "native.financesolver.co" ||
        window.location.host === "www.native.financesolver.co") &&
      ((fieldName.toLowerCase() === "est_time_trading" &&
        parseInt(value) < "12") ||
        (fieldName.toLowerCase() === "abn" && value.toLowerCase() === "no"))
    ) {
      Utils.historyPush("/do-not-qualify");
    }

    if (
      (window.location.host === "v3.ozhouseprice.com.au" ||
        window.location.host === "www.v3.ozhouseprice.com.au") &&
      fieldName.toLowerCase() === "homeowner" &&
      value.toLowerCase() === "no"
    ) {
      Utils.historyPush("/do-not-qualify");
    }

    if (
      (["au2.quickandeasyhearing.com", "www.au2.quickandeasyhearing.com", "au2.qehearing.com", "www.au2.qehearing.com"].includes(host)) &&
      fieldName.toLowerCase() === "ageabove" &&
      value.toLowerCase() === "no"
    ) {
      Utils.historyPush("/do-not-qualify");
    }

    if (["v4.mysolarcompare.com.au", "www.v4.mysolarcompare.com.au"].includes(window.location.host) &&
      fieldName.toLowerCase() === "home_owner" &&
      value.toLowerCase() === "renter"
    ) {
      Utils.historyPush("/do-not-qualify");
    }

    if (
      (host === "www.financesolver.co" || host === "financesolver.co" ||
        host === "www.superexperts.financesolver.co" || host === "superexperts.financesolver.co") &&
      ((fieldName.toLowerCase() === "selectedage" &&
        (value === "20-29" || value === "60-69" || value === "70-79" || value === "80-90")) ||
        (fieldName.toLowerCase() === "super_balance" &&
          value === "$0 - $50,000"))
    ) {
      Utils.historyPush("/do-not-qualify");
    }

    if (host === "funeral.financesolver.co" && ((fieldName.toLowerCase() === "australian_citizen" && value === "no") || fieldName.toLowerCase() === "age" && (value <= "39" || value >= '81'))) {
      Utils.historyPush("/do-not-qualify");
    }
    console.log("console", host, fieldName, value);
    if (['mylifeinsurancecompare.financesolver.co', 'www.mylifeinsurancecompare.financesolver.co'].includes(host) && fieldName.toLowerCase() === "age" && parseInt(value) >= 70) {
      Utils.historyPush('/do-not-qualify');
    }
    if (
      ["nz.mypetinsurancecompare.co", "www.nz.mypetinsurancecompare.co", "mypetinsurancecompare.co",
        "www.mypetinsurancecompare.co"].includes(host)
    ) {
      if (
        (value.toLowerCase().trim() == "none of these" ||
          value.toLowerCase().trim() == "noneofthese") &&
        fieldName.toLowerCase() === "petnsurtype"
      ) {
        Utils.historyPush("/do-not-qualify");
      }

      var petType = this.state?.fields["petnsurtype"]?.toLowerCase()?.trim();
      var petValue = parseInt(value.trim());
      if (
        ((petType == "cat" || petType == "dog") &&
          fieldName.toLowerCase() === "petnsurage" &&
          petValue >= 13) ||
        (petType == "horse" &&
          fieldName.toLowerCase() === "petnsurage" &&
          petValue >= 17) ||
        (petType == "rabbit" &&
          fieldName.toLowerCase() === "petnsurage" &&
          petValue >= 3)
      ) {
        Utils.historyPush("/do-not-qualify");
      }
    }

    if (
      ["nz.mycarinsurancecompare.co", "www.nz.mycarinsurancecompare.co", "mycarinsurancecompare.co",
        "www.mycarinsurancecompare.co"].includes(host)
    ) {
      if (
        value.toLowerCase().trim() == "200000" &&
        fieldName.toLowerCase() === "kmtravel"
      ) {
        Utils.historyPush("/do-not-qualify");
      }

      if (
        (value.toLowerCase().trim() == "2004orbefore") &&
        fieldName.toLowerCase() === "manufactureyear"
      ) {
        Utils.historyPush("/do-not-qualify");
      }
    }

    if (
      ["myhomeloancompare.com.au", "www.myhomeloancompare.com.au", "keepthebankshonest.com.au", "www.keepthebankshonest.com.au"].includes(host)
    ) {
      // if (["myhomeloancompare.com.au", "www.myhomeloancompare.com.au",].includes(host) &&
      //   fieldName.toLowerCase() === "refinance" && value.toLowerCase() === "0" 
      // ) {
      //   Utils.historyPush("/do-not-qualify");
      // }

      if (fieldName.toLowerCase() === "credit_situation" &&
        ((value.toLowerCase().trim() == "terrible") || (value.toLowerCase().trim() == "poor"))

      ) {
        Utils.historyPush("/do-not-qualify");
      }

      // if (fieldName.toLowerCase() === "employment_status"  &&
      //   ((value.toLowerCase().trim() == "self-employed")||(value.toLowerCase().trim() == "unemployed") )

      // ) {
      //   Utils.historyPush("/do-not-qualify");
      // }

    }
    if (["aus.mysolarcompare.com.au", "www.aus.mysolarcompare.com.au", "localsolarquotes.mysolarcompare.com.au",
      "www.localsolarquotes.mysolarcompare.com.au", "lsq.mysolarcompare.com.au",
      "www.lsq.mysolarcompare.com.au"].includes(host) &&
      fieldName.toLowerCase() === "home_owner" &&
      value.toLowerCase() === "renter"
    ) {
      Utils.historyPush("/do-not-qualify");
    }


    if (["creditrepair.financesolver.co", "www.creditrepair.financesolver.co"].includes(host)) {
      if (fieldName === 'declined_finance' && value === 'no') {
        Utils.historyPush("/do-not-qualify");
      }
      if (fieldName === 'level_savings' && value === '< $500') {
        Utils.historyPush("/do-not-qualify");
      }
      if (fieldName === 'overdue_bills' && value === 'no') {
        Utils.historyPush("/do-not-qualify");
      }
      if (fieldName === 'currently_employed' && value === 'No') {
        Utils.historyPush("/do-not-qualify");
      }
    }

    if (["supportwithdebt.com.au", "www.supportwithdebt.com.au",
      "debt.financesolver.co/comparenow",
      "2022.supportwithdebt.com.au/comparenow",
      "www.debt.financesolver.co/comparenow",
      "www.2022.supportwithdebt.com.au/comparenow"].includes(host)) {
      if (fieldName === 'unsecured_debt' && (value === '0 - $9,999' || value === '$0 - $9,999')) {
        Utils.historyPush("/do-not-qualify");
      }
    }

    if (["easysolarquotes.com.au", "www.easysolarquotes.com.au",].includes(host)) {
      if (fieldName === 'homeowner' && value === 'no') {
        Utils.historyPush("/do-not-qualify");
      }
    }
    if (["loancaddie.myhomeloancompare.com.au", "www.loancaddie.myhomeloancompare.com.au", "keepthebankshonest.com.au", "www.keepthebankshonest.com.au"].includes(host)) {
      if (fieldName === 'employment_status' && (value.toLowerCase() !== 'full/part-time' && value.toLowerCase() !== 'self-employed')) {
        Utils.historyPush("/do-not-qualify");
      }
    }

    if (["mycarloancompare.com.au", "www.mycarloancompare.com.au"].includes(host)) {
      if (fieldName.toLowerCase() === "lookingborrow" && parseInt(value) < 10000)
        Utils.historyPush("/do-not-qualify");
      if (fieldName.toLowerCase() === "intersted_in_vehicle_loan" && value.toLowerCase() === "justlooking")
        Utils.historyPush("/do-not-qualify");
      if (fieldName.toLowerCase() === "credit_situation" && ["poor", "terrible"].includes(value.toLowerCase()))
        Utils.historyPush("/do-not-qualify");
      if (fieldName.toLowerCase() === "employment_status" && ["unemployed", "retired", "disability"].includes(value.toLowerCase()))
        Utils.historyPush("/do-not-qualify");
      if (fieldName.toLowerCase() === "australian_citizen" && value.toLowerCase() === "no")
        Utils.historyPush("/do-not-qualify");
      if (fieldName.toLowerCase() === "over_age" && value.toLowerCase() === "no")
        Utils.historyPush("/do-not-qualify");
      if (fieldName.toLowerCase() === "employment_status" && value.toLowerCase() === "other")
        Utils.historyPush("/do-not-qualify");
    }
    if (["www.propertyinvestr.co","propertyinvestr.co"].includes(host)) {
      if (fieldName.toLowerCase() === "combined_income" && value === "Under $120k") {
        Utils.historyPush("/do-not-qualify");
      }
      if (fieldName.toLowerCase() === "income" && value == "Under_120k") {
        Utils.historyPush("/do-not-qualify");
      }
      if (this.state.fields["combined_income"] === '$300k+' && fieldName.toLowerCase() === "age_value" && value === 'Over 60') {
        // Utils.historyPush("/do-not-qualify");
      } else if (fieldName.toLowerCase() === "age_value" && value === 'Over 60') {
        Utils.historyPush("/do-not-qualify");
      }
    }
    if (["mybusinesscarfinance.com.au", "www.mybusinesscarfinance.com.au"].includes(host)) {
      if (fieldName.toLowerCase() === 'australian_citizen' && value.toLowerCase() === 'no') {
        Utils.historyPush("/do-not-qualify");
      }
    }
    if (["funeral.financesolver.co", "www.funeral.financesolver.co"].includes(host)) {
      if (fieldName.toLowerCase() === 'age' &&  ( parseInt(value) <50 || parseInt(value) > 80)) {
        Utils.historyPush("/do-not-qualify");
      }
    }
    if (["life.financesolver.co", "www.life.financesolver.co"].includes(host)) {
      if (fieldName.toLowerCase() === 'age' &&  ( parseInt(value) <=39 || parseInt(value) > 80)) {
        Utils.historyPush("/do-not-qualify");
      }
    }
    if (["www.myloan-finder.com", "myloan-finder.com"].includes(host)) {
      if (fieldName.toLowerCase() === 'borrow' &&   parseInt(value) <=499) {
        Utils.historyPush("/do-not-qualify");
      }
      if (fieldName.toLowerCase() === 'age' &&   parseInt(value) <=18) {
        Utils.historyPush("/do-not-qualify");
      }
      if (fieldName.toLowerCase() === 'credit_situation' &&   value ==='Terrible') {
        Utils.historyPush("/do-not-qualify");
      }
      if (fieldName.toLowerCase() === 'australian_citizen' &&   value ==='No') {
        Utils.historyPush("/do-not-qualify");
      }
      if (fieldName.toLowerCase() === 'employment_status' &&  ( value ==='self-employed' ||value ==='unemployed')) {
        Utils.historyPush("/do-not-qualify");
      }
    }
  };

  handleNextStep = () => {
    const { data } = this.props;
    const { activeStep } = this.state;
    if (activeStep === data.length - 1) return false;

    this.setState({ isValidate: true });
    let fieldName = data[activeStep].name;
    let fieldValue = this.state.fields[fieldName];
   
    // if(data[activeStep].type ==='date-with-next-button'){
      window.scrollTo(0, 0);
    // }

    this.redirectToDoNotDisturb(fieldName, fieldValue);
    var phoneData = data.filter((item) => {
      return item.subtype === "tel";
    });
    var phoneName = phoneData && phoneData.length > 0 && phoneData[0].name;
    if (fieldName === phoneName) {
      var phoneValue = this.state.fields[phoneName];

      if (phoneValue !== "")
        this.validatePhoneStep(this, phoneValue, data, activeStep);
    } else {
      let hasError = this.state[`has${data[activeStep].name}Error`];
      if (!hasError) {
        //dependancy questions logic start here
        var totalQLength = data && data.length;
        let fieldType = data[activeStep].type;
        if (fieldType === "radio-group" || fieldType === "select") {
          var currentFieldData = data[activeStep];

          var next_stpe_value =
            currentFieldData.values.filter((item) => {
              return item.value === fieldValue;
            }).length > 0
              ? currentFieldData.values.filter((item) => {
                return item.value === fieldValue;
              })[0].next_question
              : "0";
          next_stpe_value =
            next_stpe_value !== undefined &&
              next_stpe_value !== null &&
              next_stpe_value !== "" &&
              next_stpe_value !== "0"
              ? parseInt(next_stpe_value) > totalQLength
                ? activeStep + 1
                : parseInt(next_stpe_value) - 1
              : activeStep + 1;

          next_stpe_value =
            next_stpe_value <= activeStep ? activeStep + 1 : next_stpe_value;
          this.state.backSteps[this.state.backSteps.length] = activeStep;
          this.setState({
            activeStep: next_stpe_value,
            isValidate: false,
            isDependantQuestion: true,
          });

          this.trackSteps(next_stpe_value, fieldName, fieldValue);
        } else {
          this.state.backSteps[this.state.backSteps.length] = activeStep;
          this.setState({
            activeStep: activeStep + 1,
            isValidate: false,
            // isDependantQuestion: false,
          });

          this.trackSteps(activeStep + 1, fieldName, fieldValue);
        }
        //dependancy questions logic end here
      }
    }
  };

  trackSteps(step, text, value) {
    const sessionId = localStorage.getItem("sessionId");
    if (sessionId && step >= 0) {
      Api.trackMicrositeSteps(sessionId, step, text, value, "");
    }
  }

  validatePhoneStep(thiss, phoneValue, data, activeStep) {
    try {
      //debugger;
      const { siteData } = thiss.props;

      phoneValue = phoneValue
        .replace("+61", "0")
        .replace("+64", "0")
        .replace("+1", "").
        replace("+44", "0");
      var baseurl = "";
      if (process.env.WDS_SOCKET_PATH === "release") {
        baseurl = "https://helathinsurancefind.com.au/api/";
      } else {
        baseurl = "http://localhost:8034/api/";
      }
      var config = {
        method: "get",
        url: `${baseurl}/neutrinoapiV3/${phoneValue}/${siteData.country}/${siteData.id}`,
      };

      axios(config)
        .then(function (response) {
          var isvalid = ["ca.financesolver.co", "www.ca.financesolver.co"].includes(Utils.getHost().websiteUrl) ? true :
            response && response?.data && response?.data?.message === "true";

          if (!isvalid) {
            thiss.setState({
              isPhoneValid: false,
              isValidate: true,
              [`has${[data[activeStep].name]}Error`]: true,
              isLoading: false,
            });
            //Record inavild phone in db - start
            var dataN = JSON.stringify({
              phone: phoneValue,
              site_id: siteData.id,
            });

            var baseurl = "";
            if (process.env.WDS_SOCKET_PATH === "release") {
              baseurl = "https://helathinsurancefind.com.au/api/";
            } else {
              baseurl = "http://localhost:8034/api/";
            }

            var configN = {
              method: "post",
              url: `${baseurl}recordInvalidNumverify`,
              headers: {
                "Content-Type": "application/json",
              },
              data: dataN,
            };

            axios(configN)
              .then(function (responseN) {
                console.log(JSON.stringify(responseN.data));
              })
              .catch(function (error) {
                console.log(error);
              });
            //Record inavild phone in db - end
          } else {
            let hasError = thiss.state[`has${data[activeStep].name}Error`];
            if (!hasError) {
              thiss.state.backSteps[thiss.state.backSteps.length] = activeStep;
              var acst =
                data.length - 1 === activeStep ? activeStep : activeStep + 1;

              thiss.setState({
                activeStep: acst,
                isValidate: false,
                isPhoneValid: true,
                [`has${[data[activeStep].name]}Error`]: false,
              });

              const sessionId = localStorage.getItem("sessionId");
              if (sessionId && acst >= 0) {
                Api.trackMicrositeSteps(
                  sessionId,
                  acst,
                  "phone",
                  phoneValue,
                  ""
                );
              }
            }
          }
        })
        .catch(function (error) {
          console.log("error Numverify Validation ===", error);
        });
    } catch (ex) {
      console.log("Numverify Validation exception", ex);
    }
  }

  handleBackStep = () => {
    var presentInx = this.state.backSteps[this.state.backSteps.length - 1];
    this.state.backSteps.splice(this.state.backSteps.length - 1, 1);
    this.setState({
      activeStep: presentInx,
      isValidate: false,
    });
  };

  render() {
    const { data, heading, siteData, isFromHome } = this.props;
    const { activeStep } = this.state;

    let siteFormData = siteData.content_manage_sites.filter((item) => {
      return item.page_id === 6;
    })[0];

    return (
      <SurveyFormSliderNew
        heading={heading}
        data={data}
        activeStep={activeStep}
        handleNextStep={() => this.handleNextStep.bind(this)}
        handleBackStep={() => this.handleBackStep.bind(this)}
        callSwitch={this.callSwitch}
        value={this.state.fields}
        layoutTheme={this.props.theme_id}
        surveyLayout={siteFormData.page_title}
        isFromHome={isFromHome}
        showFinish={data.length - 1 === activeStep}
        siteFormData={siteFormData}
        country={siteData.country}
      />
    );
  }
}
const mapStateToProps = ({
  ManageSite: { isLoading, getSiteDataObject, getSiteStatus, getSiteError },
}) => ({ isLoading, getSiteDataObject, getSiteStatus, getSiteError });
export default connect(mapStateToProps, { leadData })(FifthLayoutSurvey);

FifthLayoutSurvey.defaultProp = {
  theme_id: 0,
  QBData: {},
  isFromHome: false,
};
