import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { object, string, func } from "prop-types";
import "./date-field.scss";
import Error from "../../components/error";
import fieldValidation from "../../utils/validate";
import { checkValidationRule } from "../../utils/validate";
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import Tooltip from "../../views/tool-tip";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Utils from "../../utils";

// var hovercol = "#ced4da";

const useStyles = makeStyles((theme) => ({
  formLabelroot: {
    display: "flex",
    paddingLeft: "0px",
    alignItems: "center",
    color: "#000",
    fontSize: "14px",
    // fontWeight: "bold",
  },
  formLabelrootInline: {
    paddingLeft: "0px",
    color: "#585555",
    fontSize: "30px",
    padding: " 10px",
    display: "flex",
    fontWeight: "500",
    justifyContent: "center !important",
  },
  mainDivSurveyType: {
    float: "left",
    paddingLeft: "3px",
    borderRight: "none",
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
    backgroundColor: "white",
    width: "38px",
    height: "58px",
    paddingTop: "7px",
    fontSize: "27px",
    "&:hover": {
      borderTop: `2px solid #c5c5c5`,
      borderBottom: `2px solid #c5c5c5`,
      borderLeft: `2px solid #c5c5c5`,
    },
  },
  mainDivOtherTypeStandardTemp: {
    float: "left",
    paddingLeft: "10px",
    borderRight: "none",
    fontSize: "27px",
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
    backgroundColor: "white",
    width: "45px",
    borderTop: "1px solid #ced4da",
    borderBottom: "1px solid #ced4da",
    borderLeft: "1px solid #ced4da",
    height: "57px",
    //paddingTop: "2px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  mainDivOtherTypeFifthTemp: {
    float: "left",
    paddingLeft: "10px",
    borderRight: "none",
    fontSize: "27px",
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
    backgroundColor: "white",
    width: "45px",
    borderTop: "1px solid #ced4da",
    borderBottom: "1px solid #ced4da",
    borderLeft: "1px solid #ced4da",
    height: "59px",
    //paddingTop: "2px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  IconOther: {
    color: "#7c7d7d",
  },
}));

const DateField = ({
  name,
  fieldAttributes,
  label,
  validate,
  validateExecutive,
  description,
  validationRule,
  validateOptions,
  validationCallback,
  type,
  value,
  onDateChange,
  theme_id,
  maxdate,
  iconName,
  mindate,
  yearsCount,
  themecolors,
  activestep,
}) => {
  const [errors, setError] = useState({});
  const col = themecolors.onHoverInColor;
  const [colHovor, SetolHovor] = useState(themecolors.textColor);
  const yearsNumber = yearsCount && yearsCount > 0 ? yearsCount : 100;
  const { [name]: error = "" } = errors;
  const [startDate, setStartDate] = useState(
    value !== undefined && value !== "" ? new Date(value) : ""
  );

  const setOnChangedate = (date, e) => {
    if (date !== null) {
      setStartDate(date);
      onDateChange(date);
      changeColorHoverOut();
    }
  };
  const handleErrors = (errorMsg, isVisible, e) => {
    const err = { [name]: errorMsg };
    if (checkValidationRule(isVisible, validationRule)) {
      setError({ ...err });
    } else if (isVisible === "VALIDATE_ON_TYPE" && e.key !== "Enter") {
      // this added because we want to unset error on type
      setError({ err: "" });
    }

    validationCallback(!!errorMsg);
  };

  const classes = useStyles();

  const validateFields = (isVisible, e) => {
    e = startDate;
    const { required } = validateOptions;

    if (required) {
      const errorMsg = fieldValidation.required(value, name);
      if (errorMsg) {
        handleErrors(errorMsg, isVisible, e);
        return;
      }
    }
    handleErrors("", isVisible, e);
  };

  // componentDidupdate
  useEffect(() => {
    if (validate) {
      validateFields("VALIDATE_ON_FORM_SUBMIT");
    } else if (validateExecutive) {
      validateFields("VALIDATE_ON_FORM_SUBMIT");
    }
    //$("*").css("font-family", localStorage.getItem("fontFamily"));
  });

  const hideError = () => {
    setError({ err: "" });
  };

  const changeColorHoverIn = () => {
    SetolHovor(col);
    setHoverCls("date-box__field--survey_hoverColor");
  };
  const changeColorHoverOut = () => {
    SetolHovor(themecolors.textColor);
    setHoverCls("date-box__field--survey");
  };

  const [hoverCls, setHoverCls] = useState("date-box__field--survey");
  const ExampleCustomInput = ({ value, onClick }) => {
    return theme_id === 2 ? (
      <>
        <div className="form-group">
          <input
            id={`step_${activestep}_${name}`}
            type="text"
            // className="example-custom-input"
            onClick={onClick}
            className="form-control form-control-lg"
            value={value}
            required
            // placeholder="MM/DD/YYYY"
          />
          <label id={`step_${activestep}_${name}_label`}>
            {label}
            <span>*</span>
          </label>
        </div>
      </>
    ) : (
      <>
        <input
          id={`step_${activestep}_${name}`}
          type="text"
          // className="example-custom-input"
          onClick={onClick}
          className={`date-box__field  ${type === "survey" && hoverCls} `}
          value={value}
          readOnly={true}
          style={{
            borderTop: ` ${
              type === "survey" ? `2px solid ${colHovor}` : "1px solid #ced4da"
            }`,
            borderBottom: ` ${
              type === "survey" ? `2px solid ${colHovor}` : "1px solid #ced4da"
            }`,
            borderRight: ` ${
              type === "survey" ? `2px solid ${colHovor}` : "1px solid #ced4da"
            }`,
          }}
        />
      </>
    );
  };

  return (
    <Grid>
      {!!label && theme_id !== 3 && theme_id !== 2 && (
        <FormLabel
          className={
            type === "survey"
              ? classes.formLabelrootInline
              : classes.formLabelroot
          }
        >
          {label}&nbsp;
          {validateOptions.required && type !== "survey" && (
            <span className="mendatory">*</span>
          )}
          {!!description && (
            <Tooltip placement="top-start" title={description} />
          )}
        </FormLabel>
      )}
      <div onMouseOver={changeColorHoverIn} onMouseLeave={changeColorHoverOut}>
        <div
          className={
            theme_id === 2
              ? "datePickerIcon"
              : `${
                  type === "survey"
                    ? classes.mainDivSurveyType
                    : theme_id === 1 || theme_id === "1"
                    ? classes.mainDivOtherTypeStandardTemp
                    : classes.mainDivOtherTypeFifthTemp
                }`
          }
          style={{
            borderTop: ` ${
              theme_id === 2
                ? ""
                : type === "survey"
                ? `2px solid ${colHovor}`
                : "1px solid #ced4da"
            }`,
            borderBottom: ` ${
              theme_id === 2
                ? ""
                : type === "survey"
                ? `2px solid ${colHovor}`
                : "1px solid #ced4da"
            }`,
            borderLeft: ` ${
              theme_id === 2
                ? ""
                : type === "survey"
                ? `2px solid ${colHovor}`
                : "1px solid #ced4da"
            }`,
          }}
          id={`step_${activestep}_${name}_div`}
        >
          <i
            className={`demo-icon ${iconName}`}
            style={{ color: `${type === "survey" ? colHovor : "#7a7a7a"}` }}
          />
        </div>

        <DatePicker
          className={
            theme_id === 2
              ? ""
              : `date-box__field  ${type === "survey" && hoverCls} `
          }
          selected={startDate}
          {...fieldAttributes}
          onSelect={hideError}
          onChange={(value, e) => setOnChangedate(value, e)}
          maxDate={maxdate}
          minDate={mindate}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          scrollableYearDropdown
          yearDropdownItemNumber={yearsNumber}
          customInput={<ExampleCustomInput />}
          dateFormat="dd/MM/yyyy"
          id={`step_${activestep}_${name}_dt`}
        />
      </div>
      {!!error && theme_id !== 3 && (
        <Error
          type={`${type === "survey" ? "dateErrror" : ""}`}
          errorMessage={error}
        />
      )}
    </Grid>
  );
};

DateField.propTypes = {
  fieldAttributes: object,
  classname: string,
  label: string.isRequired,
  name: string.isRequired,
  validationCallback: func.isRequired,
};

DateField.defaultProps = {
  fieldAttributes: {},
  classname: "",
  validateOptions: {
    check: false,
  },
  theme_id: 0,
};

export default DateField;
