import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-mobile-datepicker";
import Error from "../../components/error";
import { FormLabel, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  formLabelRoot: {
    display: "flex",
    paddingLeft: "0px",
    alignItems: "center",
    color: "#000",
    fontSize: "14px",
  },
  formLabelInline: {
    paddingLeft: "0px",
    color: "#585555",
    fontSize: "30px",
    padding: "10px",
    display: "flex",
    fontWeight: "500",
    justifyContent: "center !important",
  },
}));

const DateScrollPicker = ({
  name,
  label,
  onDateChange,
  theme_id,
  type,
  validateOptions,
  description,
  validate,
  validationCallback,
  maxdate,
  mindate,
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState("");
  const classes = useStyles();

  const validateDate = (date) => {
    if (!date) return "Date is required.";

    const parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime())) {
      return "Invalid date selected.";
    }

    if (
      (mindate && parsedDate < new Date(mindate)) ||
      (maxdate && parsedDate > new Date(maxdate))
    ) {
      return parsedDate < new Date(mindate)
        ? "Date is earlier than the minimum allowed."
        : "Date is later than the maximum allowed.";
    }

    return "";
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    const validationError = validateDate(date);
    setError(validationError);

    if (!validationError) {
      const localDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      );
      const formattedDate = localDate.toISOString().split("T")[0];
      onDateChange(formattedDate); 
      validationCallback(false);
    } else {
      validationCallback(true);
    }

    setIsOpen(false);
  };

  useEffect(() => {
    if (validate) {
      const validationError = validateDate(selectedDate);
      setError(validationError);
      validationCallback(!!validationError);
    }
  }, [validate, selectedDate, validationCallback]);

  const handleOpen = () => setIsOpen(true);
  const handleCancel = () => setIsOpen(false);

  const formatDate = (date) => {
    if (!date) return "";
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  };

  return (
    <div className="date-scroll-picker">
      {!!label && theme_id !== 3 && theme_id !== 2 && (
        <FormLabel
          className={
            type === "survey" ? classes.formLabelInline : classes.formLabelRoot
          }
        >
          {label}&nbsp;
          {validateOptions?.required && type !== "survey" && (
            <span className="mandatory">*</span>
          )}
          {!!description && (
            <Tooltip placement="top-start" title={description} />
          )}
        </FormLabel>
      )}
      <div className="date-picker-div">
        <input
          type="text"
          value={formatDate(selectedDate)}
          readOnly
          placeholder="MM-DD-YYYY"
          onClick={handleOpen}
          className="form-control form-control-lg date-picker-input-field"
        />
        {error && <Error errorMessage={error} />}
      </div>
      <DatePicker
        value={selectedDate || new Date()} // Default to current date
        isOpen={isOpen}
        onSelect={handleDateSelect}
        onCancel={handleCancel}
        min={new Date(mindate)}
        isPopup={true}
        max={new Date(maxdate)}
        dateFormat={["MM", "DD", "YYYY"]}
        confirmText="OK"
        cancelText="Cancel"
        showHeader={true}
        customHeader={
          <div className="datepicker-content">
            <div className="datepicker-col-1 secHeading">Year</div>{" "}
            <div className="datepicker-col-1 secHeading">Month</div>{" "}
            <div className="datepicker-col-1 secHeading">Day</div>
          </div>
        }
      />
    </div>
  );
};

DateScrollPicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onDateChange: PropTypes.func.isRequired,
  theme_id: PropTypes.number,
  type: PropTypes.string,
  validateOptions: PropTypes.object,
  description: PropTypes.string,
  validate: PropTypes.bool,
  validationCallback: PropTypes.func.isRequired,
  maxdate: PropTypes.string.isRequired,
  mindate: PropTypes.string.isRequired,
};

export default DateScrollPicker;
