import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./date-field.scss";
import Error from "../../components/error";
import { FormLabel, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  formLabelroot: {
    display: "flex",
    paddingLeft: "0px",
    alignItems: "center",
    color: "#000",
    fontSize: "14px",
  },
  formLabelrootInline: {
    paddingLeft: "0px",
    color: "#585555",
    fontSize: "30px",
    padding: "10px",
    display: "flex",
    fontWeight: "500",
    justifyContent: "center !important",
  },
}));

const CustomDatePicker = ({
  name,
  label,
  onDateChange,
  theme_id,
  type,
  validateOptions,
  description,
  validate,
  validationCallback,
  maxdate,
  mindate,
}) => {
  const [date, setDate] = useState({ month: "", day: "", year: "" });
  const [error, setError] = useState("");

  const classes = useStyles();

  const validateDate = ({ month, day, year }) => {
    if (!month || !day || !year) {
      return "All fields are required.";
    }

    const selectedDate = new Date(
      `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
    );

    if (isNaN(selectedDate.getTime())) {
      return "Please enter a valid date of birth, e.g. MM/DD/YYYY.";
    }

    if (
      selectedDate.getFullYear() !== parseInt(year, 10) ||
      selectedDate.getMonth() + 1 !== parseInt(month, 10) ||
      selectedDate.getDate() !== parseInt(day, 10)
    ) {
      return "Please enter a valid date of birth, e.g. MM/DD/YYYY.";
    }


    if (
      (mindate && selectedDate < new Date(mindate)) ||
      (maxdate && selectedDate > new Date(maxdate))
    ) {
      return selectedDate < new Date(mindate)
        ? `Maximum age limit exceeded.`
        : `You must be at least 18 years old.`;
    }

    const today = new Date();
    const age = today.getFullYear() - selectedDate.getFullYear();
    const isOlderThan18 =
      age > 18 ||
      (age === 18 &&
        today >=
          new Date(selectedDate.setFullYear(selectedDate.getFullYear() + 18)));

    return isOlderThan18 ? "" : "You must be at least 18 years old.";
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedDate = { ...date, [name]: value.trim() };
    setDate(updatedDate);

    if (
      !updatedDate.month ||
      !updatedDate.day ||
      !updatedDate.year ||
      updatedDate.year.length < 4
    ) {
      setError("");
      validationCallback(true);
      return;
    }
    const validationError = validateDate(updatedDate);
    setError(validationError);

    if (!validationError) {
      const validDate = new Date(
        `${updatedDate.year}-${updatedDate.month.padStart(2, "0")}-${updatedDate.day.padStart(2, "0")}`
      );
      const localDate = new Date(
        validDate.getTime() - validDate.getTimezoneOffset() * 60000
      );
      const formattedDate = localDate.toISOString().split("T")[0];
      onDateChange(formattedDate);
      validationCallback(false);
    } else {
      validationCallback(true);
    }
  };

  useEffect(() => {
    if (validate) {
      const validationError = validateDate(date);
      setError(validationError);
      validationCallback(!!validationError);
    }
  }, [validate, date, validationCallback]);

  return (
    <div className="custom-date-picker">
      {!!label && theme_id !== 3 && theme_id !== 2 && (
        <FormLabel
          className={
            type === "survey"
              ? classes.formLabelrootInline
              : classes.formLabelroot
          }
        >
          {label}&nbsp;
          {validateOptions?.required && type !== "survey" && (
            <span className="mandatory">*</span>
          )}
          {!!description && (
            <Tooltip placement="top-start" title={description} />
          )}
        </FormLabel>
      )}
      <div className="custom-date-picker-fields">
        <div className="month-input-div">
          <label className="month-input-label">Month</label>
          <input
            type="number"
            id="month"
            name="month"
            value={date.month}
            onChange={handleInputChange}
            min="1"
            max="12"
            placeholder="MM"
            className="month-input-field form-control form-control-lg"
          />
        </div>
        <div className="day-input-div">
          <label className="day-input-label">Day</label>
          <input
            type="number"
            id="day"
            name="day"
            value={date.day}
            onChange={handleInputChange}
            min="1"
            max="31"
            placeholder="DD"
            className="day-input-field form-control form-control-lg"
          />
        </div>
        <div className="year-input-div">
          <label className="year-input-label">Year</label>
          <input
            type="number"
            id="year"
            name="year"
            value={date.year}
            onChange={handleInputChange}
            min="1900"
            placeholder="YYYY"
            max={new Date().getFullYear()}
            className="year-input-field form-control form-control-lg"
          />
        </div>
      </div>
      {error && <Error errorMessage={error} />}
    </div>
  );
};

CustomDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onDateChange: PropTypes.func.isRequired,
  theme_id: PropTypes.number,
  type: PropTypes.string,
  validateOptions: PropTypes.object,
  description: PropTypes.string,
  validate: PropTypes.bool,
  validationCallback: PropTypes.func.isRequired,
  maxdate: PropTypes.string,
  mindate: PropTypes.string,
};

export default CustomDatePicker;
